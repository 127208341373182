// src/App.tsx

import React, { useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import { AppBar, Toolbar, Typography, IconButton, Button, Box, SwipeableDrawer, List, ListItem, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Customers from './components/Customers/Customers';
import Products from './components/Products/Products';
import Suppliers from './components/Suppliers/Suppliers';
import Invoices from './components/Invoices/Invoices';
import CustomerNewForm from './components/Customers/CustomerNewForm';
import CustomerEditForm from './components/Customers/CustomerEditForm';
import CustomerDisplayForm from './components/Customers/CustomerDisplayForm';
import ProductNewForm from './components/Products/ProductNewForm';
import ProductScanner from './components/Products/ProductScanner';
import QrCodePrinter from './components/QrCode/QrCodePrinter';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import Login from './components/Login/Login';
import ProtectedRoute from './components/PrivateRoute/PrivateRoute';
import NotFound from './components/NotFound/NotFound';
import InvoiceNewForm from './components/Invoices/InvoiceNewForm';

const App: React.FC = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: '/dashboard',
      },
    });
  };
  const handleLogout = () => {
    logout({
      returnTo: '/',
    } as Partial<LogoutOptions>)
  };
  const menuItems = [
    { label: 'Tableau de bord', path: '/dashboard' },
    { label: 'Clients', path: '/customers' },
    { label: 'Fournisseurs', path: '/suppliers' },
    { label: 'Stock', path: '/stock' },
    { label: 'Factures', path: '/invoices' },
    { label: 'Scanner', path: '/scanner' },
    { label: 'QrCode', path: '/qrcode/print' },
  ];
  return (
    <>
      <Router>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            {isAuthenticated && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleMenu}
              >
                <MenuIcon />
              </IconButton>
            )}
            <img src="/logo.png" alt="Logo" height="40px" />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
            {!isAuthenticated ? (
              <Button color="inherit" onClick={handleLogin}>
                Se connecter
              </Button>
            ) : (
              <Button color="inherit" onClick={handleLogout}>
                Se déconnecter
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <SwipeableDrawer anchor="left" open={menuOpen} onClose={toggleMenu} onOpen={toggleMenu}>
          <Box sx={{ width: 250 }}>
            <List>
              {menuItems.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem button component={Link} to={item.path} onClick={toggleMenu}>
                    <Typography variant="body1">{item.label}</Typography>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>
        <Routes>
          <Route index element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customers/:id" element={<CustomerDisplayForm customer={{ email: "j", id: 1, name: "Jordan" }} />} />
            <Route path="/customers/new" element={<CustomerNewForm />} />
            <Route path="/customers/edit/:id" element={<CustomerEditForm customer={{ email: "j", id: 1, name: "Jordan" }} onSubmit={() => { }} />} />
            <Route path="/suppliers" element={<Suppliers />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/invoices/new" element={<InvoiceNewForm />} />
            <Route path="/stock" element={<Products />} />
            <Route path="/scanner" element={<ProductScanner />} />
            <Route path="/stock/new" element={<ProductNewForm onSubmit={() => { }} />} />
            <Route path="/qrcode/print" element={<QrCodePrinter />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
