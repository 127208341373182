import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

interface Invoice {
  invoiceNumber: string;
  customerName: string;
  issueDate: string;
  dueDate: string;
  amount: number;
  status: string;
}

interface InvoiceFormProps {}

const InvoiceNewForm: React.FC<InvoiceFormProps> = () => {
  const [invoice, setInvoice] = useState<Invoice>({
    invoiceNumber: '',
    customerName: '',
    issueDate: '',
    dueDate: '',
    amount: 0,
    status: '',
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Invoice data:', invoice);
    setInvoice({
      invoiceNumber: '',
      customerName: '',
      issueDate: '',
      dueDate: '',
      amount: 0,
      status: '',
    });
  };

  return (
    <Container maxWidth="lg">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" component="h2">
          Create Invoice
        </Typography>
        <TextField
          label="Invoice Number"
          value={invoice.invoiceNumber}
          onChange={(e) => setInvoice({ ...invoice, invoiceNumber: e.target.value })}
          required
          fullWidth
          sx={{ mt: 2 }}
        />
        <TextField
          label="Customer Name"
          value={invoice.customerName}
          onChange={(e) => setInvoice({ ...invoice, customerName: e.target.value })}
          required
          fullWidth
          sx={{ mt: 2 }}
        />
        <TextField
          label="Issue Date"
          type="date"
          value={invoice.issueDate}
          onChange={(e) => setInvoice({ ...invoice, issueDate: e.target.value })}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Due Date"
          type="date"
          value={invoice.dueDate}
          onChange={(e) => setInvoice({ ...invoice, dueDate: e.target.value })}
          required
          fullWidth
          InputLabelProps={{ shrink: true }}
          sx={{ mt: 2 }}
        />
        <TextField
          label="Amount"
          type="number"
          value={invoice.amount}
          onChange={(e) => setInvoice({ ...invoice, amount: parseFloat(e.target.value) })}
          required
          fullWidth
          sx={{ mt: 2 }}
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={invoice.status}
            onChange={(e) => setInvoice({ ...invoice, status: e.target.value as string })}
            required
          >
            <MenuItem value="unpaid">Unpaid</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="overdue">Overdue</MenuItem>
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Create Invoice
        </Button>
      </Box>
    </Container>
  );
};

export default InvoiceNewForm;
     
