// UnderConstruction.tsx
import React, { useEffect } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const UnderConstruction: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isLoading, isAuthenticated, navigate]);

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <img
          src="logo.png"
          alt="Under Construction"
          style={{
            width: '100%',
            maxWidth: '500px',
            height: 'auto',
            marginBottom: '1rem',
          }}
        />
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
          }}
        >
          0.0.1
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          Se connecter
        </Button>
      </Box>
    </Container>
  );
};

export default UnderConstruction;
