// src/components/Products/Products.tsx

import * as React from 'react';
import { Container, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Paper, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface Product {
  id: number;
  name: string;
  quantity: number;
}

const Products: React.FC = () => {
  const [products, setProducts] = React.useState<Product[]>([]);
  const [newProduct, setNewProduct] = React.useState({ name: '', quantity: '' });

  const addProduct = () => {
    const product: Product = { id: products.length + 1, name: newProduct.name, quantity: parseInt(newProduct.quantity) };
    setProducts([...products, product]);
    setNewProduct({ name: '', quantity: '' });
  };

  const deleteProduct = (id: number) => {
    setProducts(products.filter(product => product.id !== id));
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h1">
          Gestion du Stock
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Button variant="contained" color="primary" onClick={addProduct} sx={{ marginLeft: 2 }}>
          Ajouter
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Quantité</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>{product.id}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => deleteProduct(product.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Products;
