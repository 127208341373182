// src/components/Suppliers/Suppliers.tsx

import * as React from 'react';
import { Container, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Paper, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface Supplier {
  id: number;
  name: string;
  email: string;
}

const Suppliers: React.FC = () => {
  const [suppliers, setSuppliers] = React.useState<Supplier[]>([]);
  const [newSupplier, setNewSupplier] = React.useState({ name: '', email: '' });

  const addSupplier = () => {
    const supplier: Supplier = { id: suppliers.length + 1, name: newSupplier.name, email: newSupplier.email };
    setSuppliers([...suppliers, supplier]);
    setNewSupplier({ name: '', email: '' });
  };

  const deleteSupplier = (id: number) => {
    setSuppliers(suppliers.filter(supplier => supplier.id !== id));
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1">
          Gestion des fournisseurs
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Button variant="contained" color="primary" onClick={addSupplier} sx={{ marginLeft: 2 }}>
          Ajouter
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers.map((supplier) => (
              <TableRow key={supplier.id}>
                <TableCell>{supplier.id}</TableCell>
                <TableCell>{supplier.name}</TableCell>
                <TableCell>{supplier.email}</TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => deleteSupplier(supplier.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Suppliers;
