// src/components/CustomerEditForm/CustomerEditForm.tsx

import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

interface Customer {
  id: number;
  name: string;
  email: string;
}

interface CustomerEditFormProps {
  customer: Customer;
  onSubmit: (customer: Customer) => void;
}

const CustomerEditForm: React.FC<CustomerEditFormProps> = ({ customer, onSubmit }) => {
  const [editedCustomer, setEditedCustomer] = useState<Customer>(customer);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(editedCustomer);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ my: 4 }}>
      <Typography variant="h6" component="h2">
        Edit Customer
      </Typography>
      <TextField
        label="Name"
        value={editedCustomer.name}
        onChange={(e) => setEditedCustomer({ ...editedCustomer, name: e.target.value })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Email"
        value={editedCustomer.email}
        onChange={(e) => setEditedCustomer({ ...editedCustomer, email: e.target.value })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Save Changes
      </Button>
    </Box>
  );
};

export default CustomerEditForm;
