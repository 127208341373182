// src/components/QrCodePrinter/QrCodePrinter.tsx

import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography, Slider, Container } from '@mui/material';
import QRCode from 'qrcode.react';

interface Product {
  id: number;
  name: string;
  description: string;
  price: number;
}

// Mock data - Replace this with the actual data source
const mockProducts: Product[] = [
  { id: 1, name: 'Product 1', description: 'Sample product 1', price: 10.99 },
  { id: 2, name: 'Product 2', description: 'Sample product 2', price: 20.99 },
  // Add more products
];

const QrCodePrinter: React.FC = () => {
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const [qrCodeSize, setQrCodeSize] = useState<number>(128);

  const handleQrCodeSizeChange = (event: Event, newValue: number | number[]) => {
    setQrCodeSize(newValue as number);
  };

  const handleProductSelection = (productId: number, isSelected: boolean) => {
    if (isSelected) {
      setSelectedProducts([...selectedProducts, productId]);
    } else {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    }
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h6" component="h2">
          Impression des QR Codes
        </Typography>
        <Box>
          {mockProducts.map(product => (
            <FormControlLabel
              key={product.id}
              control={
                <Checkbox
                  onChange={event => handleProductSelection(product.id, event.target.checked)}
                  color="primary"
                />
              }
              label={product.name}
            />
          ))}
        </Box>
        <Box>
        <Typography variant="subtitle1">Taille des QR Codes</Typography>
        <Slider
          value={qrCodeSize}
          min={64}
          max={256}
          step={8}
          onChange={handleQrCodeSizeChange}
          valueLabelDisplay="auto"
          sx={{ width: 300 }}
        />
        </Box>
       
        <Button variant="contained" color="primary" onClick={handlePrint} >
          Imprimer
        </Button>
        <Box sx={{ display: 'none', '@media print': { display: 'block' } }}>
          {selectedProducts.map(productId => {
            const product = mockProducts.find(p => p.id === productId);
            if (!product) return null;
            return (
              <Box key={product.id} sx={{ width: '20%', display: 'inline-block', textAlign: 'center', mb: 2, mt: 3}}>
                <QRCode value={JSON.stringify(product)} size={qrCodeSize} />
                <Typography>{product.name}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
};

export default QrCodePrinter;
