// src/components/Invoices/Invoices.tsx

import * as React from 'react';
import { Container, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Paper, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

interface Invoice {
  id: number;
  client: string;
  amount: number;
  date: string;
}

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [newInvoice, setNewInvoice] = React.useState({ client: '', amount: '', date: '' });
  const navigate = useNavigate();
  const addInvoice = () => {
    navigate('/invoices/new')
  };

  const deleteInvoice = (id: number) => {
    setInvoices(invoices.filter(invoice => invoice.id !== id));
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h1">
          Gestion des Factures
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Button variant="contained" color="primary" onClick={addInvoice} sx={{ marginLeft: 2 }}>
          Ajouter
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.id}</TableCell>
                <TableCell>{invoice.client}</TableCell>
                <TableCell>{invoice.amount}</TableCell>
                <TableCell>{invoice.date}</TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => deleteInvoice(invoice.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Invoices;
