// src/components/Dashboard/Dashboard.tsx

import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const Dashboard: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();

  const cards = [
    {
      title: 'Clients',
      count: 120,
    },
    {
      title: 'Stock',
      count: 210,
    },
    {
      title: 'Factures',
      count: 210,
    }
  ];

  const renderCard = (card: { title: string; count: number }) => (
    <Grid item xs={12} sm={6} md={3}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          borderRadius: '8px',
          boxShadow: 3,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          {card.title}
        </Typography>
        <Typography variant="h3" component="p">
          {card.count}
        </Typography>
      </Paper>
    </Grid>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant='h5' component="h1" gutterBottom>
          Tableau de bord
        </Typography>
        <Typography variant='h6' component="h2" gutterBottom>
         {isAuthenticated && user?.name}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {cards.map((card) => renderCard(card))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
