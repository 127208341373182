// src/components/ProductScanner/ProductScanner.tsx

import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { Html5Qrcode } from 'html5-qrcode';
import ProductDisplayForm from './ProductDisplayForm';

interface Product {
  name: string;
  description: string;
  price: number;
}

const ProductScanner: React.FC = () => {
  const scannerRef = useRef<HTMLDivElement>(null);
  const html5QrCode = useRef<Html5Qrcode | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [scanning, setScanning] = useState(false);

  const startScanning = async () => {
    if (!scannerRef.current) return;
    html5QrCode.current = new Html5Qrcode(scannerRef.current.id);
    setScanning(true);

    try {
      await html5QrCode.current.start(
        { facingMode: 'environment' },
        {
          fps: 10,
          qrbox: { width: 250, height: 250 },
        },
        (decodedText: string) => {
          const scannedProduct = JSON.parse(decodedText) as Product;
          setProduct(scannedProduct);
          stopScanning();
        },
        (errorMessage: string) => {
          console.error(`QR code scanning error: ${errorMessage}`);
        },
      );
    } catch (error) {
      console.error(`Error starting QR code scanner: ${error}`);
      setScanning(false);
    }
  };

  const stopScanning = () => {
    if (html5QrCode.current?.isScanning) {
      html5QrCode.current.stop();
    }
    setScanning(false);
  };

  useEffect(() => {
    return () => {
      stopScanning();
    };
  }, []);

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h1">
          Scanner
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {product ? (
          <ProductDisplayForm product={product} />
        ) : (
          <>
            <Box
              ref={scannerRef}
              id="product-scanner"
              sx={{
                width: '100%',
                maxWidth: '400px',
                height: 0,
                paddingBottom: '100%',
                position: 'relative',
                mt: 2,
              }}
            >
              {scanning ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 10,
                  }}
                >
                  <Typography color="white" textAlign="center" sx={{ mt: 2 }}>
                    Scanning...
                  </Typography>
                </Box>
              ) : null}
            </Box>
            {scanning ? (
              <Button variant="contained" onClick={stopScanning} sx={{ mt: 2 }}>
                Stop Scanning
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={startScanning} sx={{ mt: 2 }}>
                Start Scanning
              </Button>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default ProductScanner;
