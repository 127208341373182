// src/components/ProductDisplayForm/ProductDisplayForm.tsx

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

interface Product {
  name: string;
  description: string;
  price: number;
}

interface ProductDisplayFormProps {
  product: Product;
}

const ProductDisplayForm: React.FC<ProductDisplayFormProps> = ({ product }) => {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h6" component="h2">
        Product Details
      </Typography>
      <TextField
        label="Name"
        value={product.name}
        InputProps={{ readOnly: true }}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Description"
        value={product.description}
        InputProps={{ readOnly: true }}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Price"
        value={product.price}
        InputProps={{ readOnly: true }}
        fullWidth
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default ProductDisplayForm;
