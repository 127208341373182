// src/components/CustomerNewForm/CustomerNewForm.tsx

import React, { useState } from 'react';
import { Box, TextField, Button, Typography, FormControl, InputLabel, Select, MenuItem, Container } from '@mui/material';

interface Customer {
  address: string;
  city: string;
  email: string;
  firstName: string;
  gender: 'Homme' | 'Femme';
  lastName: string;
  companyName: string;
  phone: string;
  customerType: 'Personne Physique' | 'Entreprise';
  zipCode: string;
}

const initialCustomerState: Customer = {
  address: '',
  city: '',
  email: '',
  firstName: '',
  gender: 'Homme',
  lastName: '',
  companyName: '',
  phone: '',
  customerType: 'Personne Physique',
  zipCode: '',
};

const CustomerNewForm: React.FC = () => {
  const [customer, setCustomer] = useState<Customer>(initialCustomerState);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCustomer(initialCustomerState);
  };

  return (
    <Container maxWidth="lg">
    <Box component="form" onSubmit={handleSubmit} sx={{ my: 4 }}>
      <Typography variant="h5" component="h1">
        Ajouter un client
      </Typography>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="customer-type-label">Type de client</InputLabel>
        <Select
          labelId="customer-type-label"
          value={customer.customerType}
          onChange={(e) => setCustomer({ ...customer, customerType: e.target.value as 'Personne Physique' | 'Entreprise' })}
        >
          <MenuItem value="Individual">Personne Physique</MenuItem>
          <MenuItem value="Business">Entreprise</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Prénom"
        value={customer.firstName}
        onChange={(e) => setCustomer({ ...customer, firstName: e.target.value })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Nom"
        value={customer.lastName}
        onChange={(e) => setCustomer({ ...customer, lastName: e.target.value })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="gender-label">Genre</InputLabel>
        <Select
          labelId="gender-label"
          value={customer.gender}
          onChange={(e) => setCustomer({ ...customer, gender: e.target.value as 'Homme' | 'Femme' })}
        >
          <MenuItem value="Male">Homme</MenuItem>
          <MenuItem value="Female">Femme</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Email"
        value={customer.email}
        onChange={(e) => setCustomer({ ...customer, email: e.target.value })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Téléphone"
        value={customer.phone}
        onChange={(e) => setCustomer({ ...customer, phone: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Nom de l'entreprise"
        value={customer.companyName}
        onChange={(e) => setCustomer({ ...customer, companyName: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Adresse"
        value={customer.address}
        onChange={(e) => setCustomer({ ...customer, address: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Ville"
        value={customer.city}
        onChange={(e) => setCustomer({ ...customer, city: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Code Postal"
        value={customer.zipCode}
        onChange={(e) => setCustomer({ ...customer, zipCode: e.target.value })}
        fullWidth
        sx={{ mt: 2 }}
      />
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Ajouter
      </Button>
    </Box>
    </Container>
  );
};

export default CustomerNewForm;

