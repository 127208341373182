// src/components/ProductNewForm/ProductNewForm.tsx

import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import QRCode from 'qrcode.react';

interface Product {
  name: string;
  description: string;
  price: number;
}

interface ProductNewFormProps {
  onSubmit: (product: Product) => void;
}

const ProductNewForm: React.FC<ProductNewFormProps> = ({ onSubmit }) => {
  const [product, setProduct] = useState<Product>({ name: '', description: '', price: 0 });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(product);
    setProduct({ name: '', description: '', price: 0 });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ my: 4 }}>
      <Typography variant="h6" component="h2">
        Add New Product
      </Typography>
      <TextField
        label="Name"
        value={product.name}
        onChange={(e) => setProduct({ ...product, name: e.target.value })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Description"
        value={product.description}
        onChange={(e) => setProduct({ ...product, description: e.target.value })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Price"
        type="number"
        value={product.price}
        onChange={(e) => setProduct({ ...product, price: parseFloat(e.target.value) })}
        required
        fullWidth
        sx={{ mt: 2 }}
      />
      <Box textAlign="center" sx={{ mt: 4 }}>
        <QRCode value={JSON.stringify(product)} />
      </Box>
      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        Add Product
      </Button>
    </Box>
  );
};

export default ProductNewForm;
