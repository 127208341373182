// src/components/Customers/Customers.tsx

import * as React from 'react';
import { Container, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Paper, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

interface Customer {
  id: number;
  name: string;
  email: string;
}

const Customers: React.FC = () => {
  const [Customers, setCustomers] = React.useState<Customer[]>([]);
  const [newCustomer, setNewCustomer] = React.useState({ name: '', email: '' });
  const navigate = useNavigate();

  const addCustomer = () => {
    navigate('/customers/new')
  };

  const deleteCustomer = (id: number) => {
    setCustomers(Customers.filter(Customer => Customer.id !== id));
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" component="h1">
          Gestion des Clients
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Button variant="contained" color="primary" onClick={addCustomer} sx={{ marginLeft: 2 }}>
          Ajouter
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Customers.map((Customer) => (
              <TableRow key={Customer.id}>
                <TableCell>{Customer.id}</TableCell>
                <TableCell>{Customer.name}</TableCell>
                <TableCell>{Customer.email}</TableCell>
                <TableCell>
                  <IconButton color="error" onClick={() => deleteCustomer(Customer.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Customers;
