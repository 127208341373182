import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
interface Auth0ProviderWithHistoryProps {
    children: React.ReactNode;
  }
const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
    const domain = 'prod-sdmecaflex.eu.auth0.com';
    const clientId = 'F3sOiHugdS2IF3KdJU9lyWosMbzgAxdr';
    const redirectUri = window.location.origin;

    const onRedirectCallback = (appState: any) => {
        window.history.replaceState(
            {},
            document.title,
            appState && appState.returnTo ? appState.returnTo : window.location.pathname
        );
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{ redirectUri: redirectUri }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
