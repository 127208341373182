// src/components/CustomerDisplayForm/CustomerDisplayForm.tsx

import React from 'react';
import { Box, TextField, Typography } from '@mui/material';

interface Customer {
  id: number;
  name: string;
  email: string;
}

interface CustomerDisplayFormProps {
  customer: Customer;
}

const CustomerDisplayForm: React.FC<CustomerDisplayFormProps> = ({ customer }) => {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h6" component="h2">
        Customer Details
      </Typography>
      <TextField
        label="Name"
        value={customer.name}
        InputProps={{ readOnly: true }}
        fullWidth
        sx={{ mt: 2 }}
      />
      <TextField
        label="Email"
        value={customer.email}
        InputProps={{ readOnly: true }}
        fullWidth
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default CustomerDisplayForm;
